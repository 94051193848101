<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin from '@shared/mixins/community';

export default {
  mixins: [utilsMixin, communityMixin()],
  props: {
    tag: {
      type: [String, Object],
      default: 'div',
    },
    member: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canDelete() {
      return (
        this.sharedHasExpertPlan
        && this.member.role !== 'OWNER'
        && this.canManageCommunityEntity
      );
    },
    displayName() {
      return (
        this.member.display_name
        || this.member.firstname
      );
    },
    avatar() {
      if (this.member.avatar) {
        return this.member.avatar.startsWith('http')
          ? this.member.avatar
          : `${this.$env.apiResourcesURL}/${this.member.avatar}`;
      }

      const index = this.displayName[0].toLowerCase().charCodeAt(0) % 24;

      // eslint-disable-next-line
      return `${this.$env.apiDomain}/img/avatars/${index + 1}.png`;
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    class="box flex-between-center is-flex-wrap-wrap"
  >
    <div class="flex-center-center">
      <figure class="flex-none image is-48x48">
        <img class="is-rounded w-full h-full ofit-cover" :src="avatar" :alt="displayName">
      </figure>
      <p class="ml-3">
        {{ displayName }}
        <span v-if="isStoreAdmin" class="has-text-dark is-size-8">
          <br>
          ({{ member.firstname }} {{ member.lastname }})
        </span>
      </p>
    </div>
    <p class="mt-2 ml-5">
      <b-tooltip position="is-top">
        <template #content>
          Membre depuis le {{ member.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
        </template>
        <span
          class="tag valign-middle"
          :class="{
            'is-dark': member.type === 'USER',
            'is-primary': member.type === 'CUSTOMER',
          }"
          v-t="`community.member_type.${member.type}`"
        />
      </b-tooltip>
      <b-button
        v-if="canDelete"
        class="ml-3 valign-middle"
        type="is-danger"
        icon-left="trash"
        inverted
        @click="$emit('remove', member)"
      />
    </p>
  </component>
</template>
