<script>
import APIGroup from '@shared/services/API/Community/Group';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  i18n: {
    messages: {
      fr: {
        training_item: '{name} (0 apprenant) | {name} ({n} apprenant) | {name} ({n} apprenants)',
      },
    },
  },
  data() {
    return {
      newImport: {
        trainings: [],
      },
      isLoading: false,
    };
  },
  computed: {
    form() {
      return {
        trainings: {
          label: 'Ajouter tous les apprenants des formations sélectionnées',
          selectLabel: 'Choisissez...',
          type: 'multiselect',
          column: 'is-12',
          options: this.trainingsAsOptions,
          inputAttrs: {
            alwaysOpen: true,
          },
        },
      };
    },
    trainingsAsOptions() {
      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        return null;
      }

      return trainings.data.reduce((prev, curr) => {
        prev[curr.uuid] = this.$tc(
          'training_item',
          curr.counters.nb_customer,
          { name: curr.name },
        );
        return prev;
      }, {});
    },
  },
  methods: {
    handle() {
      const data = { ...this.newImport };

      this.isLoading = true;
      APIGroup.importMembers(this.group.uuid, data)
        .then(() => {
          this.$emit('done');
          this.$buefy.dialog.alert(`
            Les apprenants sélectionnés sont en cours d'import...
            <strong>Attention : le traitement peut prendre plusieurs minutes !</strong>
          `);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    // only in user context
    const loader = this.$buefy.loading.open({ container: this.$el });
    this.$store.dispatch('trainings/fetch')
      .finally(() => loader.close());
  },
};
</script>

  <template>
    <AppCardModal
      tag="form"
      class="is-relative"
      hasCloseBtn
      @close="$emit('cancel')"
      @submit.prevent="dataIsValid(isLoading) && handle()"
    >
      <template #title>
        Importer des membres dans cet espace
      </template>
      <template #body>
        <div class="columns is-multiline">
          <div
            v-for="(field, k) in form"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newImport"
            />
          </div>
        </div>
      </template>
      <template #foot>
        <b-button type="is-text" @click="$emit('cancel')">
          Annuler
        </b-button>
        <b-button
          type="is-primary is-custom"
          native-type="submit"
          :loading="isLoading"
        >
          Importer
        </b-button>
      </template>
    </AppCardModal>
  </template>
